import React from 'react';
import './Posts.scss';
import Container from '~/components/Container';
import PostPreview from '../PostPreview';
import SectionStayConnectedForBlog from '../SectionStayConnectedForBlog';
import { BlogPost } from '../../../types/BlogPost';

type Props = {
  posts: BlogPost[];
  isPreview: boolean;
};

const Posts: React.FC<Props> = ({ posts, isPreview }) => {
  return (
    <Container className="TDB-BlogPosts">
      {posts.length === 0 && <div className="post-row" />}

      <div className="TDB-BlogPosts__PostsGroup">
        {posts.slice(0, 2).map((p) => {
          return <PostPreview key={p.slug} post={p} isPreview={isPreview} />;
        })}
      </div>

      <SectionStayConnectedForBlog />

      <div className="TDB-BlogPosts__PostsGroup">
        {posts.slice(2).map((pst) => {
          return <PostPreview key={pst.slug} post={pst} isPreview={isPreview} />;
        })}
      </div>
    </Container>
  );
};

export default Posts;
