import * as React from 'react';
import './SearchInput.scss';

type Props = {
  value: string;
  onChange: (val: string) => void;
};

const SearchInput: React.FC<Props> = ({ value, onChange }) => (
  <div className="TDB-SearchInput">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#C8C8C8"
        d="M14.497 16.617c-1.647 1.119-3.653 1.584-5.624 1.303-1.972-.28-3.768-1.286-5.037-2.82-1.27-1.535-1.92-3.488-1.827-5.477.094-1.99.926-3.872 2.334-5.28 1.408-1.408 3.29-2.24 5.28-2.334 1.99-.094 3.942.558 5.476 1.827 1.535 1.27 2.54 3.065 2.821 5.037.28 1.971-.184 3.977-1.303 5.624l.096.095 4.848 4.847c.28.28.439.663.439 1.06 0 .399-.158.78-.44 1.062-.28.28-.662.439-1.06.439s-.78-.158-1.061-.44l-4.94-4.94-.002-.003zM10 5c-1.326 0-2.598.526-3.536 1.464C5.526 7.402 5 8.674 5 10s.526 2.597 1.464 3.535C7.402 14.473 8.674 15 10 15s2.597-.527 3.535-1.465C14.473 12.597 15 11.325 15 10c0-1.326-.527-2.598-1.465-3.536C12.597 5.526 11.325 5 10 5z"
      />
    </svg>

    <input
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder="Search blog"
      className="TDB-SearchInput__input"
      type="search"
    />
  </div>
);

export default SearchInput;
