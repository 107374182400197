import React from 'react';
import './NoPostsFound.scss';
import ClassName from '~/utils/helpers/ClassName';
import Container from '../../../components/Container';
import Typography from '../../../components/Typography';
import NotFoundSVG from '../../../assets/images/not-found-illustration.svg';

interface Props {
  className?: string;
  searchString?: string;
}

const NoPostsFound: React.FC<Props> = props => {
  const { className, searchString } = props;
  return (
    <Container className={ClassName.join('TDB-NoPostsFound', className)}>
      <div className="TDB-NoPostsFound__Card">
        <Typography fontSize="heading-3" fontWeight="bold" color="brand-900" align="center">
          No posts found
        </Typography>
        {searchString && (
          <Typography as="div" fontSize="subheading-3" fontWeight="medium" color="neutral-800" className="mt-2 mb-6" align="center">
            We couldn’t find any blog posts matching "{searchString}"
          </Typography>
        )}
        <img alt="No posts found" className="mt-6" src={NotFoundSVG} />
      </div>
    </Container>
  );
};

export default NoPostsFound;
