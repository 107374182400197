import React from 'react';
import './TopSection.scss';
import Typography from '../../../components/Typography';
import Container from '../../../components/Container';

const TopSection: React.FC = () => {
  return (
    <div className="TDB-TopSection">
      <Container className="TDB-TopSection__container">
        <Typography as="p" fontWeight="semi-bold" fontSize="subheading-1" color="brand-100">
          Welcome to
        </Typography>
        <Typography as="h1" fontWeight="bold" className="TDB-TopSection__title mb-4" fontSize="heading-1" color="brand-100">
          TileDB Blog
        </Typography>
        <Typography className="TDB-TopSection__subtitle" as="p" fontSize="body" color="neutral-main">
          Read stories and the latest news about TileDB, our products and the data management ecosystem
        </Typography>
      </Container>
    </div>
  );
};

export default TopSection;
