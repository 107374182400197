import * as React from 'react';
import { Link } from 'gatsby';
import showdown from 'showdown';
import BlogPostMarkup from '@components/BlogPostMarkup';
import Tag from '@components/Tag';
import Typography from '@components/Typography';
import Container from '@components/Container';
import formatDate from '@utils/helpers/formatDate';
import './FeaturedArticle.scss';
import getPostPathName from '@utils/helpers/getPostPathName';
import calculateBlogPostReadingTime from '@utils/helpers/calculateBlogPostReadingTime';
import links from '@constants/links';
import stripTag from '@utils/helpers/stripTag';
import { BlogPost } from '@custom-types/BlogPost';
import conditionalPreviewPath from '@utils/helpers/conditionalPreviewPath';
import createCMSMediaURL from '@utils/helpers/createCMSMediaURL';
import isPublishedPost from '@utils/helpers/isPublishedPost';
import { BlogPostTag } from '@custom-types/BlogPostTag';
import PostPublishStateIndicator from '@components/PostPublishStateIndicator';

const showdownConverter = new showdown.Converter();

type Props = {
  post: BlogPost;
  isPreview: boolean;
};

const FeaturedArticle: React.FC<Props> = (props) => {
  const { post, isPreview } = props;
  const markup = showdownConverter.makeHtml(post.attributes.body);
  const markupStrippedFromAnchors = React.useMemo(() => stripTag(markup), [markup]);
  const isPublished = isPublishedPost(post);

  return (
    <div className="TDB-FeaturedArticle">
      <Container className="TDB-FeaturedArticle__thumbnail-wrapper">
        <div className="TDB-FeaturedArticle__top">
          <img
            className="TDB-FeaturedArticle__thumbnail"
            src={`${createCMSMediaURL(post.attributes.image?.data?.attributes?.url)}`}
            alt="featured article"
          />
        </div>
      </Container>
      <Container>
        <Link to={conditionalPreviewPath(getPostPathName(post.attributes.slug), isPreview)} className="TDB-FeaturedArticle__card">
          <PostPublishStateIndicator isPublished={isPublished} isPreview={isPreview} /> {/* hidden in production */}
          <div className="TDB-FeaturedArticle__main">
            <div className="TDB-FeaturedArticle__header mb-4">
              <Typography className="TDB-FeaturedArticle__date" fontWeight="semi-bold" fontSize="body" color="brand-800">
                {formatDate(post.attributes.datePublished)}
              </Typography>

              {post.attributes.tags?.data?.length ? (
                <div className="TDB-FeaturedArticle__tags">
                  {post.attributes.tags?.data?.map((tag: BlogPostTag) => (
                    <Tag key={tag.attributes.slug}>
                      <Link to={`${links.blog}?tag=${tag.attributes.slug}`}>{tag.attributes.name}</Link>
                    </Tag>
                  ))}
                </div>
              ) : null}
            </div>
            <Typography
              align="center"
              fontSize="heading-3"
              className="TDB-FeaturedArticle__title mb-4"
              as="h2"
              fontWeight="bold"
              color="brand-900"
            >
              {post.attributes.title}
            </Typography>
            <Typography align="center" color="neutral-800" className="mb-4 TDB-FeaturedArticle__body">
              <BlogPostMarkup markup={`${markupStrippedFromAnchors.slice(0, 300)}...`} />
            </Typography>
            <div className="TDB-FeaturedArticle__bottom">
              {post?.attributes?.author?.data?.attributes ? (
                <div className="TDB-FeaturedArticle__author">
                  <div
                    className="TDB-FeaturedArticle__author-image"
                    style={{
                      backgroundImage: `url('${createCMSMediaURL(
                        post.attributes.author.data.attributes.photo.data?.attributes.formats.thumbnail.url
                      )}')`,
                    }}
                  />
                  <div className="TDB-FeaturedArticle__author-info">
                    <Typography as="p" fontWeight="semi-bold" className="TDB-FeaturedArticle__author-name">
                      {post.attributes.author.data.attributes.name}
                    </Typography>
                    <Typography fontSize="caption-list" className="TDB-FeaturedArticle__author-title">
                      {post.attributes.author.data.attributes.title}
                    </Typography>
                  </div>
                </div>
              ) : null}
              <div className="TDB-FeaturedArticle__duration">{calculateBlogPostReadingTime(markupStrippedFromAnchors)} read</div>
            </div>
          </div>
        </Link>
      </Container>
    </div>
  );
};

export default FeaturedArticle;
