import React from 'react';
import { ClientSideBlog } from '../page-components/Blog';
import { ClientSideBlogPostTemplate } from '../templates/BlogPostTemplate';

const Preview: React.FC<any> = (props: any) => {
  const { params } = props;
  const page = params['*'];

  if (process.env.GATSBY_ENV === 'production' || !page) {
    return null;
  }

  switch (page) {
    case 'blog':
      return <ClientSideBlog />;
    case page.match(/^blog\/[a-z0-9]+(?:-[a-z0-9]+)*$/)?.input:
      return <ClientSideBlogPostTemplate {...props} />;
    default:
      return null;
  }
};

export default Preview;
