import React from 'react';
import './BlogTags.scss';
import ClassName from '@utils/helpers/ClassName';
import { BlogPostTag } from '../../../types/BlogPostTag';

interface Props {
  tags: BlogPostTag[];
  activeTag: string;
  onChange: (tag: string) => void;
}

const BlogTags: React.FC<Props> = (props) => {
  const { activeTag, onChange, tags } = props;

  return (
    <div className="TDB-BlogTags">
      <button onClick={() => onChange('')} className={ClassName.join(ClassName.bind({ active: activeTag === '' }))}>
        All Articles
      </button>
      {tags.map((t: BlogPostTag) => {
        const { id, attributes } = t;
        const { slug, name } = attributes;

        return (
          <button onClick={() => onChange(slug)} key={id} className={ClassName.join(ClassName.bind({ active: slug === activeTag }))}>
            {name}
          </button>
        );
      })}
    </div>
  );
};

export default BlogTags;
